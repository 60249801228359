import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"

const ExcluStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: ${props => props.theme.colors.primary.enplus};
  color: #fff;
  position: relative;

  @media (max-width: 767px) {
    padding: 5px 20px;
  }

  @media (max-width: 550px) {
    padding: 5px 10px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const ExcluLogo = styled.img`
  width: 100%;
  margin: 0px 5px 0px 0px;
  max-width: 55px;

  @media (max-width: 480px) {
    max-width: 45px;
  }

  @media (max-width: 380px) {
    max-width: 35px;
  }
`

const FirstText = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  display: flex;
  align-items: center;

  span {
    color: ${props => props.theme.colors.white.base};
    font-size: 24px;
    line-height: 1;
    padding: 8px 8px 6px 8px;
    margin-left: 10px;
    border: 2px solid #ffffff;
    border-radius: 15px 0px;

    @media (max-width: 480px) {
      font-size: 21px;
      padding: 5px 5px 5px;
      border-radius: 10px 0px;
    }

    @media (max-width: 380px) {
      font-size: 18px;
      margin-left: 7px;
    }
  }

  @media (max-width: 767px) {
    flex-basis: ${({ flexBasis }) => (flexBasis ? "100%" : "auto")};
    justify-content: center;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }

  @media (max-width: 380px) {
    font-size: 24px;
  }
`

const SecondText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 20px;

  span {
    font-size: 50px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 10px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 380px) {
      font-size: 28px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const ThirdText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 20px;

  span {
    font-size: 50px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 10px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 380px) {
      font-size: 28px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 15px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const FourthText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 10px;

  span:first-of-type {
    background: ${props => props.theme.colors.white.base};
    color: ${props => props.theme.colors.primary.enplus};
    font-size: 28px;
    line-height: 1;
    font-family: "BebasNeueBold";
    font-weight: bold;
    padding: 8px 8px 6px 8px;
    margin: 0px 10px 0px 0px;
    border-radius: 15px 0px;

    @media (max-width: 480px) {
      font-size: 22px;
      padding: 7px 8px 7px;
      border-radius: 10px 0px;
      margin: 0px 7px 0px 0px;
    }
  }

  span:nth-of-type(2) {
    color: ${props => props.theme.colors.white.base};
    font-size: 24px;
    line-height: 1;
    font-family: "BebasNeueBold";
    font-weight: bold;
    padding: 8px 8px 6px 8px;
    margin-left: 10px;
    border: 2px solid #ffffff;
    border-radius: 15px 0px;

    @media (max-width: 480px) {
      font-size: 22px;
      padding: 5px 5px 5px;
      border-radius: 10px 0px;
      margin-left: 7px;
    }
  }

  div {
    text-align: left;
    text-transform: uppercase;
    font-size: 28px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    line-height: 1;

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    margin-left: 7px;
  }
`

const CloseButton = styled.a`
  background: none;
  color: #fff;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: #fff;
  }
`

const ExcluStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const { pathname } = useLocation()
  var currentPath = ""
  var ButtonHide = false
  var regex = /(shop|lyneup|lynefit|lynehome|panier|cart)/

  let isOreillerPage = false
  let isMatelasPage = false
  let isOffresPage = false

  let oreillerRegex = /(oreiller)/
  let matelasRegex = /(matelas|sommier|panier|cart)/
  let offresRegex = /(offres)/

  if (isBrowser) {
    currentPath = window.location.pathname
    ButtonHide = regex.test(currentPath)

    isOreillerPage = oreillerRegex.test(currentPath)
    isMatelasPage = matelasRegex.test(currentPath)
    isOffresPage = offresRegex.test(currentPath)
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("ExcluStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("ExcluStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  useEffect(() => {
    checkVisibilityBanner()
  }, [intl.locale])

  return (
    !LyneHomeStickyHide && (
      <ExcluStickyBannerWrapper
        lang={intl.locale}
        className="LyneHomeStickyBanner"
      >
        {ButtonHide ? (
          <Container>
            {isMatelasPage ? (
              <TextWrapper>
                <LinkBox to="/shop/offres-enplus/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <Text lang={intl.locale}>
                  <FirstText>
                    <ExcluLogo src="https://static.percko.com/uploads/dd021852-2089-40fb-b040-0994bb19948e.png" />
                    Jusqu'à
                  </FirstText>
                  <FourthText>
                    <span>-40%</span>
                    <div>
                      <strong>avec le code</strong>
                    </div>
                    <span>Exclu</span>
                  </FourthText>
                </Text>
              </TextWrapper>
            ) : isOreillerPage ? (
              <TextWrapper>
                <LinkBox to="/shop/offres-enplus/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <Text lang={intl.locale}>
                  <FirstText>
                    <ExcluLogo src="https://static.percko.com/uploads/dd021852-2089-40fb-b040-0994bb19948e.png" />
                    Jusqu'à
                  </FirstText>
                  <FourthText>
                    <span>-40%</span>
                    <div>
                      <strong>avec le code</strong>
                    </div>
                    <span>Exclu</span>
                  </FourthText>
                </Text>
              </TextWrapper>
            ) : isOffresPage ? (
              <TextWrapper>
                <LinkBox to="/shop/offres-enplus/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <Text lang={intl.locale}>
                  <FirstText>
                    <ExcluLogo src="https://static.percko.com/uploads/dd021852-2089-40fb-b040-0994bb19948e.png" />
                    Jusqu'à
                  </FirstText>
                  <FourthText>
                    <span>-40%</span>
                    <div>
                      <strong>avec le code</strong>
                    </div>
                    <span>Exclu</span>
                  </FourthText>
                </Text>
              </TextWrapper>
            ) : (
              <TextWrapper>
                <LinkBox to="/shop/offres-enplus/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <Text lang={intl.locale}>
                  <FirstText flexBasis="100%">
                    <ExcluLogo src="https://static.percko.com/uploads/dd021852-2089-40fb-b040-0994bb19948e.png" />
                    Avec le code <span>Exclu</span>
                  </FirstText>
                  <SecondText>
                    <span>-20€</span>
                    <div>
                      sur <strong>1 T-shirt</strong>
                      <br /> Lyne
                    </div>
                  </SecondText>
                  <ThirdText>
                    <span>-95€</span>
                    <div>
                      sur <strong>2 T-shirts</strong>
                      <br /> Lyne
                    </div>
                  </ThirdText>
                </Text>
              </TextWrapper>
            )}
          </Container>
        ) : (
          <Container>
            <LinkBox to="/shop/offres-enplus/">
              <LinkButtonTitle>Je découvre</LinkButtonTitle>
            </LinkBox>
            <Text lang={intl.locale}>
              <FirstText flexBasis="100%">
                <ExcluLogo src="https://static.percko.com/uploads/dd021852-2089-40fb-b040-0994bb19948e.png" />
                Avec le code <span>Exclu</span>
              </FirstText>
              <SecondText>
                <span>-20€</span>
                <div>
                  sur <strong>1 T-shirt</strong>
                  <br /> Lyne
                </div>
              </SecondText>
              <ThirdText>
                <span>-95€</span>
                <div>
                  sur <strong>2 T-shirts</strong>
                  <br /> Lyne
                </div>
              </ThirdText>
            </Text>
          </Container>
        )}
        <CloseButton onClick={hideBanner}>×</CloseButton>
      </ExcluStickyBannerWrapper>
    )
  )
}

export default injectIntl(ExcluStickyBanner)
